.header-news-custom-modal.ant-modal {
  position: fixed !important;
  right: 0;
  top: 0;
  max-width: 100% !important;

  @media screen and (min-width: 768px) {
    body {
      max-width: 500px !important;
    }
  }
}

.header-news-custom-modal.ant-modal .ant-modal-content {
  height: 100vh;
  overflow-y: scroll;
}
.notification-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.notification-item.unread {
  background-color: #f0f8ff;
}

.notification-item:hover {
  background-color: #e6f0ff;
}

.notification-content {
  width: 100%;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.notification-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.news-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-message {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.see-more-btn {
  font-size: 14px;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 10px;
}

.notification-time {
  font-size: 12px;
  color: #888;
}

.no-notification {
  display: block;
  color: #6b7280;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 8px;
}

.badge {
  background-color: #007bff;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 12px;
  text-transform: uppercase;
}
