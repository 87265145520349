body {
  touch-action: manipulation;
}

/* Mobile styles */
@media (max-width: 767px) {
  .ant-card-body {
    overflow: scroll;
  }

  .ant-picker-dropdown {
    left: 0;
    top: unset;
    bottom: 0;
  }

  .ant-picker-dropdown .ant-picker-content {
    height: 150px;
  }

  .ant-picker-dropdown .ant-picker-datetime-panel {
    flex-direction: column;
  }

  .ant-picker-dropdown .ant-picker-time-panel-column {
    justify-content: space-around;
  }

  ul.ant-picker-time-panel-column {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

.title-container {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container img {
  max-height: 36px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  min-width: 80px;
}

td.ant-table-cell,
th.ant-table-cell {
  min-width: 160px;
  max-width: 300px;
}

td.ant-table-cell.ant-table-cell-fix-right,
th.ant-table-cell.ant-table-cell-fix-right {
  min-width: 80px;
  max-width: 100px;
}

th.ant-table-cell:first-of-type,
td.ant-table-cell:first-of-type {
  min-width: unset;
}

.list-users td.ant-table-cell:first-child {
  min-width: 200px;
}

.list-table-first td.ant-table-cell:first-child {
  min-width: 100px;
}

.sub-menu-item-selected {
  background-color: #fff9f0;
}

.buttonSubmit {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
}

.buttonSubmit:hover {
  border: 1px solid #423f3c !important;
  color: #423f3c !important;
}

.buttonSubmit.enabled {
  background-color: #fcd0b1;
}

/* custom menu layout for mobile */
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  top: 12px;
  border-radius: initial;
}

.ant-layout-sider-children {
  overflow-y: scroll;
}
.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}

.ant-layout-header .ant-space-item {
  display: flex;
  justify-content: center;
}

h5.ant-typography {
  margin-bottom: 0;

  @media only screen and (max-width: 600px) {
    min-width: 100px;
  }
}

a.linkMap {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* handle custom css dark */

.isDark + .ant-layout .ant-table-row:nth-child(even) {
  background-color: #423f3c;
}

.isDark
  + .ant-layout
  .ant-table-row:nth-child(even)
  .ant-table-cell-fix-right-first {
  background-color: #423f3c;
}

.isDark
  + .ant-layout
  .ant-table-row:nth-child(even)
  > td.ant-table-cell-row-hover {
  background: #423f3c !important;
}

.isDark.sub-menu-item-selected {
  background-color: #423f3c;
}

.isDark.ant-dropdown-menu-light .ant-dropdown-menu-item-selected {
  background-color: #423f3c;
  color: #fff;
}

.isDark
  + .ant-layout
  .ant-table-
  .ant-spin-nested-loading
  .ant-table-row:nth-child(even)
  .ant-table-cell-fix-right-first {
  background-color: #423f3c;
  z-index: 999;
}

.isDark,
.isDark + .ant-layout .ant-page-header {
  color: #fff;
  .ant-breadcrumb-link a {
    color: #fff;
  }

  .ant-typography {
    color: #fff;
  }
}

/* handle custom css light table */
.isLight + .ant-layout .ant-table-row:nth-child(even) {
  background-color: #e7dcd3;
}

.isLight
  + .ant-layout
  .ant-table-row:nth-child(even)
  .ant-table-cell-fix-right-first {
  background-color: #e7dcd3;
}

.isLight
  + .ant-layout
  .ant-table-row:nth-child(even)
  > td.ant-table-cell-row-hover {
  background: #e7dcd3 !important;
}

.listFilter {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.isEnabled {
  background-color: #fcd0b1;
}

.renderImages {
  display: flex;
  overflow: scroll;
}

.imgsWrapper {
  width: 275px;
  height: 275px;
  cursor: pointer;
  position: relative;
}

.imgsWrapper img {
  width: 275px;
  height: 100%;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .imgsWrapper {
    width: 200px;
    height: 200px;
  }

  .imgsWrapper img {
    width: 200px;
  }
}

.removeIcon {
  z-index: 998;
  position: absolute;
  left: 0;
  top: 0;
}

.copyPath {
  z-index: 998;
  position: absolute;
  left: 0;
  top: 0;
}

.custom-dropdown {
  position: absolute;
  top: -35px !important;
  transform: translateX(0);
}

/* dashboard deals in month */
.dashboardDeals {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 100%;
  gap: 0px;
}

.dashboardDealsItem {
  width: 30%;
  border: 1px solid #1ee0e0;
  border-radius: 20px;
  margin-right: 10px;
  padding: 20px;
  background-color: #ffff;
}

.dashboardDealsItemSecond {
  width: 40%;
  border: 1px solid #1ee0e0;
  border-radius: 20px;
  margin-right: 10px;
  padding: 20px;
  background-color: #ffff;
}

@media (max-width: 767px) {
  .dashboardDeals {
    flex-direction: column;
    gap: 10px;
  }
  .dashboardDealsItem,
  .dashboardDealsItemSecond {
    width: 100%;
  }

  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
    display: none;
  }
}

.fontWeightThin {
  font-weight: 300;
}

.fontWeightRegular {
  font-weight: 400;
}

.fontWeightMedium {
  font-weight: 500;
}

.fontWeightBold {
  font-weight: 700;
}

.fontSizeNormal {
  font-size: 16px;
}

.fontSizeMedium {
  font-size: 18px;
}

.fontSizeLarge {
  font-size: 24px;
}

.colorGreen {
  color: #52c41a;
}

.colorYellow {
  color: #d4b106;
}

.colorRed {
  color: #ff4d4f;
}

.tableDashboard tr.ant-table-row:nth-child(odd) {
  background-color: #fff;
}

.tableDashboard tr.ant-table-row:nth-child(odd) > td.ant-table-cell {
  background-color: #fff;
}
.tableDashboard tr.ant-table-row:nth-child(even) > td.ant-table-cell {
  background-color: #e7dcd3;
}

.isDark + .ant-layout .tableDashboard tr.ant-table-row:nth-child(odd) {
  background-color: #141414;
}

.isDark
  + .ant-layout
  .tableDashboard
  tr.ant-table-row:nth-child(odd)
  > td.ant-table-cell {
  background-color: #141414;
}

.isDark + .ant-layout .tableDashboard tr.ant-table-row:nth-child(even) {
  background-color: #423f3c;
}

.isDark
  + .ant-layout
  .tableDashboard
  tr.ant-table-row:nth-child(even)
  > td.ant-table-cell {
  background-color: #423f3c;
}

.requiredLabel .ant-form-item-label {
  position: relative;
}

.requiredLabel .ant-form-item-label label {
  position: relative;
  padding-left: 8px;
}

.requiredLabel .ant-form-item-label::after {
  position: absolute;
  content: "*";
  color: #ff4d4f;
  z-index: 999;
  left: 0px;
  top: 0px;
  font-size: 12px;
}

span.ant-menu-title-content {
  text-transform: initial;
}

th.ant-table-cell.apartments-duplicated.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  min-width: 125px;
}
