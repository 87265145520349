.notification-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.notification-message-details {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
  white-space: normal;
}

.notification-time {
  font-size: 12px;
  color: #888;
}
