@keyframes ring {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.headerBell {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  animation: ring 0.5s ease-in-out infinite;
}

.bellWithoutRing {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -5px;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.notificationBadge {
  cursor: pointer;
  position: absolute;
  right: -5px;
  bottom: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
}
